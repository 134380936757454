/*---------------------------------------*\
   BS Custom Variables
\*---------------------------------------*/

$primary: #7accc8;
$dark: #161e21;
$white: #fff;
$text: #94908c;
$danger: #f00;

$theme-colors: (
  primary: $primary,
  dark: $dark,
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

/*---------------------------------------*\
   Other Variables
\*---------------------------------------*/

// Transition Time
$time: 0.3s;
// Transition Ease
$ease: linear;
// Base font type
$type: sans-serif;
// Base font size for REM calculate
$bs: 16;
// Base Line height
$lh: 10;

$line-height: 1.625 !default;
$leading: round($bs * $line-height);
$leading-rem: $leading / $bs;
// Responsive breakpoints
$screen_mobile: 480px;
$screen_mobile_max: (480 - 1)*1px;
$screen_tablet: 768px;
$screen_tablet_max: (769 - 1)*1px;
$screen_desktop: 992px;
$screen_desktop_max: (992 - 1)*1px;
$screen_large: 1200px;
$screen_large_max: (1200 - 1)*1px;

$gutter: 2rem;
$offset: ($gutter/2);
$offset-xs: 5px;
$offset-sm: 10px;
$offset-md: ($offset*2);
$offset-lg: ($offset*4);
$offset-xl: ($offset*6);
$offset-xxl: ($offset*8);
$offset-xxxl: ($offset*10);

/*---------------------------------------*\
   Fonts
\*---------------------------------------*/

@mixin fb() {
  font-family: 'Raleway', sans-serif;
}
@mixin fh() {
  font-family: 'Raleway', sans-serif;
}

/*---------------------------------------*\
   hamburger css
\*---------------------------------------*/

$hamburger-padding-x: 1rem !default;
$hamburger-padding-y: 1rem !default;
$hamburger-layer-width: 40px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.8 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
