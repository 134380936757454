.footer {
  padding: $offset 0;
  color: $dark;
  background-color: $white;
  &__nav {
    max-width: 840px;
    margin: auto;
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .8rem 0 1rem;
      @media(max-width: $screen_desktop) {
        flex-wrap: wrap;
      }
      > li {

        > a {
          > span {
            @include fs(14);
          }
        }
        &.active {
          color: $primary;
        }
        @media(max-width: $screen_desktop) {
          flex-basis: 50%;
          padding: 1rem 0;
        }
        @media(max-width: $screen_mobile_max) {
          flex-basis: 100%;
        }
      }
    }
  }

  &__sec {
    display: flex;
    justify-content: center;
  }
  &__logo {
    padding: 1rem 2rem;
    img {
      max-width: 76px;
    }
  }
  &__social {
    display: flex;
    align-items: center;
    > ul {
      display: flex;
      align-items: center;
      li {
        > a {
          padding: $offset;
        }
      }
    }

  }
  &__copy {
    text-align: center;
    padding: $offset-md;
    p {
      @include fs(14);
      color: $text;
    }
  }
}
