@import url('https://fonts.googleapis.com/css?family=Raleway');
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?k2t1hb');
  src:  url('../fonts/icomoon.eot?k2t1hb#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?k2t1hb') format('truetype'),
  url('../fonts/icomoon.woff?k2t1hb') format('woff'),
  url('../fonts/icomoon.svg?k2t1hb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e942";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin2:before {
  content: "\eaca";
}
