

/*---------------------------------------*\
   page text
\*---------------------------------------*/

.section_pagetext {
  box-shadow: 0 0 38px 2px rgba(22, 30, 33, 0.19);
  background-image: linear-gradient(45deg, #161e21 0%, #233035 100%);
  padding: $offset-xxl 0;

  h2 {
    margin: auto;
    @include fs(20);
    color: $primary;
    text-align: center;
    font-weight: 500;
    @media(min-width: $screen_desktop) {
      max-width: 215px;
    }
  }
  p {
    max-width: 570px;
    text-align: justify;
    margin: auto;
    display: block;
    color: $white;
  }
}

/*---------------------------------------*\
   page head
\*---------------------------------------*/

.section_pagebanner {
  @include bg_set();
  max-height: 430px;
  position: relative;
  &__card {
    position: relative;
    top:-3rem;
    max-width: 385px;
    padding: 2rem;
    box-shadow: 0 0 38px 2px rgba(22, 30, 33, 0.1);
    background-color: $white;
    h1 {
      @include fs(24);
      color: $dark;
    }
  }

  &__wrap {

  }
  &:after {
    position: absolute;
    width: 100%;
    height: 38px;
    background-color: #161e21;
    opacity: 0.4;
    z-index: 2;
  }
}


/*---------------------------------------*\
   page head
\*---------------------------------------*/

.section_pagehead {
  @include bg_set();
  header {
    padding: $offset-xl $offset;
    @media(min-width: $screen_tablet) {
      padding: $offset-xl;
    }
    h1 {
      color: $dark;
      @include fs(24);
      text-transform: uppercase;
    }
    p {
      @include fs(18);
      color: $dark;
    }
  }
}
/*---------------------------------------*\
   blurb
\*---------------------------------------*/

.section_blurbs {
  padding: $offset-xl 0;
  &__card {
    @include material-box();
    padding: pxtorem(32);
    text-align: center;
    img {
      max-height: 53px;
      margin:  $offset 0 $offset-md;
    }
    h3 {
      @include fs(17);
      font-weight: 500;
      color: $dark;
      min-height: 50px;

    }
    .divider {
      width: 93px;
      margin: auto;
      height: 1px;
      background-color: #939393;
      opacity: 0.5;
    }
    strong {
      display: block;
      color: $dark;
      padding: $offset-md 0;
    }
    a {
      @include fs(13);
      padding: pxtorem(9) pxtorem(18);
      letter-spacing: normal;
      font-weight: 500;


    }
  }
}
/*---------------------------------------*\
   about
\*---------------------------------------*/

.section_about {
  background-color: rgba(#ebebeb,.25);
  padding: $offset-xl 0 $offset;

  header {
    padding-bottom: 2rem;
    h1 {
      @include fs(24);
      color: $dark;
      font-weight: 500;
    }
    h2 {
      @include fs(18);
      color: $dark;
      font-weight: 500;
      display: inline-block;
      position: relative;
      &:after,
      &:before {
        content:'';
        position: absolute;
        height: 5px;
        border-top: 1px solid $primary;
        top: 14px;
        width: 600px;
      }
      &:before {
        right: 100%;
        margin-right: 1rem;
      }
      &:after {
        left: 100%;
        margin-left: 1rem;
      }
    }
  }
}


.section_imgslider {
  box-shadow: 0 0 38px 2px rgba(22, 30, 33, 0.19);
  background-image: linear-gradient(45deg, rgba(#161e21, .2) 0%, rgba(#233035, .2) 100%);
  padding: $offset-lg 0;
  img {
    max-width: 100%;
    box-shadow: 0 0 38px 2px rgba(22, 30, 33, 0.15);
  }
  .slick-slide {
    padding: 0 1rem;
  }
}

.section_findus {
  background-color: rgba(#ebebeb,.25);
  padding: $offset-xl 0;
  img {
    max-width: 100%;
    box-shadow: 0 0 38px 2px rgba(22, 30, 33, 0.15);
  }
  header {
    h1 {
      @include fs(24);
      font-weight: 500;
    }
    .divider {
      max-width: 455px;
      margin-bottom: 3rem;
    }
  }
  &__nav {
    padding: $offset 0;
    max-width: 460px;
    display: flex;
    justify-content: space-between;
    address {
      color: $dark;
      font-weight: 500;
    }
    a {
      color: $dark;
      font-weight: bold;
      img {
        max-width: 100%;
        margin: 0 10px;
      }
    }
  }
  &__boxes {
    padding-top: 6rem;
  }
  &__box {
    display: flex;
    span {
      color: $primary;
      padding: 0 $offset-md;
    }
    p, address {
      @include fs(15);
      font-weight: 500;
    }
  }
  &__info {
    margin-top: 2rem;
    .divider {
      max-width: 460px;
    }
  }
}

/*---------------------------------------*\
   CTA
\*---------------------------------------*/

.section_callus {
  @include bg_set();

  h1 {
    color: $dark;
    font-weight: 500;
    @include fs(24);
    padding: $offset-xl $offset;
    @media(min-width: $screen_tablet) {
      padding: $offset-xl;
    }
    img {
      max-width: 100%;
    }
    a {
      color: $text;
      &:hover {
        color: $primary;
      }
      @media(max-width: $screen_mobile_max) {
        display: block;
      }
    }
  }
}

.divider {
  background: $primary;
  width: 100%;
  height: 1px;
  margin: $offset 0;
}

.section_second {
  padding: $offset-xl 0;

  .office {
    max-width: 455px;
  }
  header {
    .divider {
      max-width: 455px;
      margin-bottom: 3rem;
    }
  }
  h1 {
    color: $dark;
    font-weight: 500;
    @include fs(24);
  }
  &__nav {
    padding: $offset 0;
    max-width: 460px;
    display: flex;
    justify-content: space-between;
    address {
      color: $dark;
      font-weight: 500;
    }
    a {
      color: $dark;
      font-weight: bold;
      img {
        max-width: 100%;
        margin: 0 10px;
      }
    }
  }
  &__card {
    max-width: 455px;
    margin-top: 2rem;
    position: relative;
    width: 100%;
    padding: 2rem;
    box-shadow: 0 0 38px 2px rgba(22, 30, 33, 0.1);
    background-color: $white;
    h1 {
      @include fs(24);
      color: $dark;
    }
  }
  &__reverse {
    flex-direction: column-reverse;
    @media(min-width: $screen_desktop) {
      flex-direction: row;
    }
  }
}

.ovhidden {
  overflow: hidden;
  max-width: 530px;
  margin: auto;
}
