/*---------------------------------------*\
   Default Styles
\*---------------------------------------*/

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: $bs*1px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  overflow-x: hidden;
  @include fb();
  font-weight: 400;
  color: $text;
  overflow: auto;
  font-size: 14px;
  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    font-size: 15px;
  }
  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    font-size: 16px;
  }
  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 99999999;
    background-color: $white;
    transition: all 0.8s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &.loaded {
    backface-visibility: hidden;
    &::before {
      opacity: 0;
      z-index: -1;
    }
  }
}
body#tinymce::before {
  display: none !important;
}
.wrap {
  overflow-x: hidden;
}

.wrap,
.footer {
  @include clearfix();
}

a {
  color: inherit;
  text-decoration: none;
  @include transition();
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

p {
  display: inline-block;
  width: 100%;
  margin: 0 0 $offset 0;
}

nav {
  ul {
    @include ul_reset();
  }
}

section {
  header {
    h1 {
      color: $dark;
      @include fh();
      @include fs(24);
      padding-bottom

      : $offset;
    }
  }
}

// Tables
.material-box {
  @include material-box();
  padding: $offset $offset-md;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: $primary;
}

.regular {
  p {
    @include fs(15);
    font-weight: 500;
    margin-bottom: 2rem;
  }
  h2 {
    img {
      max-height: 38px;
      margin-right: 10px;
      @include fs(18);
    }
  }
  ul {
    list-style: none;
    margin-bottom: 4rem;
    li {
      margin: $offset auto;
      margin-left: 2rem;
      position: relative;
      &:before {
        content: '✓';
        display: inline-block;
        vertical-align: baseline;
        color: $primary;
        position: absolute;
        @include fs(24);
        left: -2rem;
        top: -2px;
      }
    }
  }
}

.form_success h2 {
  color: #fff;
  font-size: 2rem;
  padding: 2rem;
  line-height: 3rem;
  margin-top: 1rem;

}

.form_error {
  color: #f74141;
  font-size: 1.5rem;
  padding: 2rem;
  font-weight: 400;
  margin-top: 1rem;
}

.alert-danger {
  color: #fff;
  background: #da5a5a;
  padding: 1rem;
  margin-top: 1rem;
}
