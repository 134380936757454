.header {
  position: relative;
  height: 80px;
  z-index: 100;
  background: $white;
  width: 100%;
  @include transition();
  &__logo {
    width: auto;
    position: relative;
    float: left;
    text-align: center;
    transform: scale(1);
    padding: $offset;
    > a {
      text-align: center;
    }
    img {
      @include img-fluid();
      max-width: 100px;
    }
  }
  #navigation {
    @include fs(15);
    display: none;
    width: 100%;
    float: none;
    text-align: center;
    @media(max-width: $screen_desktop) {
      padding-bottom: 2rem;
    }
    a {
      text-decoration: none;
    }
    ul {
      padding: 2rem 0;
      padding-top: 80px;
      li {
        display: block !important;
        opacity: 0;
        > a {
          display: block;
          text-transform: uppercase;
          width: 100%;
          padding: $offset $offset $offset 0;
          opacity: 1;
          color: $primary;
          @include transition();
          &:hover{
          color: $dark;
          };
        }
      }
    }
    body.screen_md &, body.screen_lg & {
      width: auto;
      display: block !important;
      float: right;
      ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        li {
          opacity: 1 !important;
          display: inline-block !important;
          width: auto;
          a {
            padding: 2rem 1rem;
          }
          &.active {
            color: $white;
          }
        }
      }
      a.btn {
        margin-left: 4rem;
        position: relative;
        bottom: 3px;
      }
    }
  }

  #toggle {
    cursor: pointer;
    position: relative;
    outline: none;
    float: right;
    padding: 2rem;
    @media(max-width: $screen_desktop) {
      text-align: right;
    }
    body.screen_md &, body.screen_lg & {
      display: none !important;
    }
  }
  .menu_active & {
    height: unset;
    background-color: rgba($white, 0.9);
  }
  .menu_active.screen_md &, .menu_active.screen_lg & {
    background: $dark !important;
  }
}

.secondnav {
  background: $dark;
  color: $white;
  &__in {
    max-width: 840px;
    margin: auto;
  }
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: .8rem 0 1rem;
    @media(max-width: $screen_desktop) {
      flex-wrap: wrap;
    }
    li {
      > a {
        span {
          @include fs(14);
          text-transform: uppercase;
          @include transition();
          &:hover {
            color: $primary;
          }
        }
      }
      &.active {
        color: $primary;
      }
      @media(max-width: $screen_desktop) {
        flex-basis: 50%;
        padding: 1rem 0;
      }
      @media(max-width: $screen_mobile_max) {
        flex-basis: 100%;
      }
    }
  }
}
