/*---------------------------------------*\
   Forms
\*---------------------------------------*/
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
input::placeholder,
textarea::placeholder {
  color: $dark !important;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: $offset-xs;
  font-weight: bold;
  color: $primary;
}

input.error {
  border-color: $danger;
}
label.error {
  color: $danger;
  @include fs(14);
  font-weight: 300;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}

.form-group {
  margin-bottom: $offset;
}

.form-submit {
  margin-top: $offset-md;
  text-align: center;
}

.form-control {
  @include fs(14);
  font-weight: 400;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba($text, 0.3);
  color: $dark;
  padding: (6 / $bs * 1rem) 0;
  border-radius: 2px;
  line-height: ($bs + 10) / $bs * 1rem;
  width: 100%;
  height: 35 / $bs * 1rem;
  display: block;
  outline: 0 !important;
  box-shadow: none;
  -webkit-appearance: none;
  @include transition();
  &:-webkit-autofill {
    background: $dark;
  }
}

textarea.form-control {
  background: rgba($white, 0.16);
  min-height: 90px;
  height: auto !important;
  outline: 0 !important;
  &:focus,
  &:active {
    background: rgba($white, 0.16);
    outline: none !important;
  }
}

.form-checkbox {
  position: relative;
  label {
    position: relative;
    @include fs(14);
    color: $white;
    font-weight: 400;
    text-align: justify;

    > a {
      text-decoration: underline;
    }
    &:before {
      content: '';
      width: 20px;
      height: 19px;
      border: 1px solid $primary;
      background: rgba($white, .16);
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -2px;
      margin-right: 10px;
    }
    &:after {
      content: '✓';
      position: absolute;
      top: -5px;
      left: 2px;
      color: $white;
      font-size: 28px;
      line-height: 1;
      opacity: 0;
      @include transition();
    }
  }
  input {
    position: absolute;
    opacity: 0;
  }
  :checked + label:after {
    opacity: 1;
  }
}

#message {
  margin-top: 9px;
  padding: 5px;
  font-size: small;
  color: #fff;
  font-weight: 400;
  border: none;
  &.message_error {
    background-color: $danger;
  }
}

#message.message_success {
  background-color: mediumseagreen;
}
